import palette from "../palette"
export default {
  root: {
    backgroundColor: palette.webfluentBlue,
    color: palette.white,
    borderRadius: 20,
    marginRight: 10,
    padding: "2rem",
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);",
    "&:hover": {
      opacity: 0.5,
      backgroundColor: palette.webfluentBlue,
    },
  },
  text: {
    padding: "6px 15px",
  },
}
