import MuiButton from './MuiButton';
import MuiPaper from './MuiPaper';
import MuiTypography from './MuiTypography';
import MuiContainer from './MuiContainer';

export default {
  MuiButton,
  MuiPaper,
  MuiTypography,
  MuiContainer
};
