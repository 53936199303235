module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-178469269-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"WebFluent","short_name":"starter","start_url":"/","background_color":"#25897c","theme_color":"#beddda","display":"minimal-ui","icon":"src/assets/images/logo_favicon-04.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"d6940d2f2ea560fc0974d4bcef4e1e03"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
